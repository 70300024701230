import { Alter, Note, NoteType, Octave, Step, Pitch } from "types/score";

function asStrings(o: Octave) {
  return [
    `C${o}`,
    `C#${o}/Db${o}`,
    `D${o}`,
    `D#${o}/Eb${o}`,
    `E${o}`,
    `F${o}`,
    `F#${o}/Gb${o}`,
    `G${o}`,
    `G#${o}/Ab${o}`,
    `A${o}`,
    `A#${o}/Bb${o}`,
    `B${o}`,
  ];
}

function parseString(el: Element, selector: string) {
  return el.querySelector(selector)?.textContent || undefined;
}

function parseInteger(el: Element, selector: string) {
  const string = parseString(el, selector);
  return string ? parseInt(string) : undefined;
}

function parseNote(note: Element): Note {
  const type = parseString(note, "type") as NoteType;
  const lyric = parseString(note, "lyric text");
  const duration = parseInteger(note, "duration") as number;

  const octave = parseInteger(note, "octave") as Octave;
  const step = parseString(note, "step") as Step;
  const alter = parseInteger(note, "alter") as Alter;

  const baseNote = {
    lyric,
    type,
  };

  const isRest = !!note.querySelector("rest");
  if (isRest) {
    return {
      ...baseNote,
      duration,
      isGrace: false,
      isRest: true,
    };
  }

  const pitch = { octave, step, alter };

  const isGrace = !!note.querySelector("grace");
  if (isGrace) {
    return {
      ...baseNote,
      isRest: false,
      isGrace: isGrace,
      pitch,
    };
  }

  return {
    ...baseNote,
    duration,
    isRest,
    isGrace,
    pitch,
  };
}

function parseNotes(part: Element): Note[] {
  const notes = [] as Note[];
  for (const noteEl of part.querySelectorAll("note")) {
    const note = parseNote(noteEl);
    notes.push(note);
  }
  return notes;
}

/* Turn a pitch into a number value */
function toNumber(pitch: Pitch) {
  const { step, octave, alter = 0 } = pitch;
  const value = StepValue[step];

  return octave * 12 + value + alter;
}

function fromAlter(c: string) {
  if (c === "#") return 1;
  if (c === "b") return -1;
  return undefined;
}

function fromFormattedString(str: string): Pitch {
  const step = str[0] as Step;
  const alter = str.length === 3 ? fromAlter(str[1]) : undefined;
  const octave = parseInt(str[str.length - 1]) as Octave;

  return { alter, octave, step };
}

function sortFromString(a: string, b: string) {
  const aNote = fromFormattedString(a);
  const bNote = fromFormattedString(b);

  return toNumber(aNote) - toNumber(bNote);
}

const StepValue = {
  C: 0,
  D: 2,
  E: 4,
  F: 5,
  G: 7,
  A: 9,
  B: 11,
};

const Notes = {
  asStrings,
  parseNote,
  parseNotes,
  sortFromString,
  toNumber,
};

export default Notes;
