import React from "react";
import styled from "styled-components";

import Formatter from "Formatter";
import Interval from "./Interval";
import Spreadsheet from "./Spreadsheet";
import { Octave } from "types/score";
import { getFacts } from "./facts";

const FactsWrapper = styled.div`
  padding: 4rem 0;
  margin: 0 auto;
`;

const FactGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem 0;
`;

const Fact = styled.div`
  width: 50%;
`;

const Label = styled.div`
  font-weight: bold;
  margin-top: 0.5rem;
`;
const Value = styled.div``;

export default function Facts({ score }: { score: Document }) {
  const [selectedOctave, setSelectedOctave] = React.useState<Octave>(4);
  const facts = getFacts(score);

  if (!facts) return null;

  const { artist, graces, octaves, steps, title } = facts;
  const { starting, lowest, highest } = facts.notes;

  const o = selectedOctave;
  const octave = octaves[o];

  return (
    <FactsWrapper>
      <h2>
        {title} − {artist}
      </h2>
      <Spreadsheet facts={facts} selectedOctave={selectedOctave} />
      <FactGrid>
        <Fact>
          <Label>Range: </Label>
          <Interval lowest={lowest} highest={highest} />
        </Fact>
        <Fact>
          <Label>Starting note: </Label>
          <Value>{Formatter.fromNote(starting)}</Value>
        </Fact>
        <Fact>
          <Label>Range (Grace Notes):</Label>
          {graces ? (
            <Interval lowest={graces.lowest} highest={graces.highest} />
          ) : (
            <span>n/a</span>
          )}
        </Fact>
        <Fact></Fact>
        <Fact>
          <Label>Smallest step down: </Label>
          <Interval
            down
            lowest={steps.smallest.down[1]}
            highest={steps.smallest.down[0]}
          />
        </Fact>
        <Fact>
          <Label>Smallest step up: </Label>
          <Interval
            lowest={steps.smallest.up[0]}
            highest={steps.smallest.up[1]}
          />
        </Fact>
        <Fact>
          <Label>Biggest step down: </Label>
          <Interval
            down
            lowest={steps.biggest.down[1]}
            highest={steps.biggest.down[0]}
          />
        </Fact>
        <Fact>
          <Label>Biggest step up: </Label>
          <Interval
            lowest={steps.biggest.up[0]}
            highest={steps.biggest.up[1]}
          />
        </Fact>
        <Fact>
          <Label>Octave time</Label>
          {octaves.map((octave, i) => (
            <Value key={i}>
              <button onClick={() => setSelectedOctave(i as Octave)}>
                Octave {i}
              </button>
              : {octave.percent}
            </Value>
          ))}
        </Fact>
        <Fact>
          <Label>Octave {selectedOctave} Breakdown</Label>
          <table>
            <tbody>
              <tr>
                <td>
                  C{o}-E{o}
                </td>
                <td>{octave.breakdown[0]}</td>
              </tr>
              <tr>
                <td>
                  F{o}-G#{o}:
                </td>
                <td>{octave.breakdown[1]}</td>
              </tr>
              <tr>
                <td>
                  A{o}-B{o}:
                </td>
                <td>{octave.breakdown[2]}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <table>
            <tbody>
              {octave.notes.map(({ note, percent }) => (
                <tr key={note}>
                  <td>{note}:</td>
                  <td>{percent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fact>
      </FactGrid>
    </FactsWrapper>
  );
}
