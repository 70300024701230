import Formatter from "Formatter";
import Notes from "models/Notes";
import type { Grace, Note, Octave, Tone } from "../types/score";

export function getKey(score: Document) {
  const root = score.querySelector("root");
  const alter = root?.querySelector("root-alter")?.textContent;
  const key = alter === "-1" ? "flat" : "sharp";

  return key;
}

export interface Steps {
  down: Tone[];
  up: Tone[];
}

export interface NoteBreakdown {
  note: string;
  percent: string;
}

export interface OctaveFacts {
  breakdown: string[];
  notes: NoteBreakdown[];
  percent: string;
}

export interface SheetFacts {
  artist: string;
  graces?: { lowest: Grace; highest: Grace };
  notes: { starting: Tone; lowest: Tone; highest: Tone };
  octaves: OctaveFacts[];
  steps: { biggest: Steps; smallest: Steps };
  title: string;
}

export function getFacts(score: Document) {
  const voice = getVoicePart(score);

  if (!voice) return null;

  const title = getSongTitle(score);
  const artist = getArtist(score);
  const notes = Notes.parseNotes(voice);

  const starting = getStartingNote(notes);
  const [lowest, highest] = getBoundaryNotes(notes);
  const steps = getSteps(notes);
  const octaves = getOctaveTimes(notes);
  const graces = getGraceNoteRange(notes);

  return {
    artist,
    graces,
    notes: { starting, lowest, highest },
    octaves,
    steps,
    title,
  };
}

function getVoicePart(score: Document) {
  const parts = score.querySelectorAll("score-part");
  const voiceId = [...parts]
    .find(
      (part) =>
        part.querySelector("instrument-name")?.textContent?.toLowerCase() ===
        "voice"
    )
    ?.getAttribute("id");
  return score.querySelector(`part[id=${voiceId}]`);
}

function getSongTitle(score: Document) {
  const creditWords = score.querySelector("credit-words")?.textContent;
  const workTitle = score.querySelector("work-title")?.textContent;

  return creditWords || workTitle || "";
}

function getArtist(score: Document) {
  const creator = score.querySelector("creator")?.textContent;
  const [, creditWords] = score.querySelectorAll("credit-words");

  return creator || creditWords?.textContent || "";
}

function getStartingNote(notes: Note[]) {
  return notes.find<Tone>((n): n is Tone => !n.isRest && !n.isGrace)!;
}

function getGraceNoteRange(notes: Note[]) {
  const graces = notes.filter((n): n is Grace => n.isGrace);

  if (!graces.length) return undefined;

  let lowest = graces[0];
  let highest = lowest;
  for (const grace of graces) {
    if (grace.isRest) continue;

    if (Notes.toNumber(grace.pitch) > Notes.toNumber(highest.pitch))
      highest = grace;
    if (Notes.toNumber(grace.pitch) < Notes.toNumber(lowest.pitch))
      lowest = grace;
  }

  return { lowest, highest };
}

function getBoundaryNotes(notes: Note[]): [Tone, Tone] {
  let minNote = getStartingNote(notes);
  let maxNote = minNote;
  for (const note of notes) {
    if (note.isRest) continue;
    if (note.isGrace) continue;

    if (Notes.toNumber(note.pitch) > Notes.toNumber(maxNote.pitch))
      maxNote = note;
    if (Notes.toNumber(note.pitch) < Notes.toNumber(minNote.pitch))
      minNote = note;
  }

  return [minNote, maxNote];
}

function getSteps(notes: Note[]) {
  const tones = notes.filter((n): n is Tone => !n.isRest && !n.isGrace);

  const steps = tones
    .map((tone, i, arr) => {
      if (i === arr.length - 1) return NaN;
      const note = Notes.toNumber(tone.pitch);
      const next = Notes.toNumber(arr[i + 1].pitch);
      return next - note;
    })
    .slice(0, tones.length - 1);

  let biggestUp, biggestDown, smallestUp, smallestDown;
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    if (step === 0) {
      continue;
    } else if (step > 0) {
      if (biggestUp === undefined) biggestUp = i;
      if (smallestUp === undefined) smallestUp = i;

      if (steps[i] > steps[biggestUp]) biggestUp = i;
      if (steps[i] < steps[smallestUp]) smallestUp = i;
    } else {
      if (biggestDown === undefined) biggestDown = i;
      if (smallestDown === undefined) smallestDown = i;

      if (steps[i] < steps[biggestDown]) biggestDown = i;
      if (steps[i] > steps[smallestDown]) smallestDown = i;
    }
  }

  const biggest = {
    up: [tones[biggestUp as number], tones[(biggestUp as number) + 1]],
    down: [tones[biggestDown as number], tones[(biggestDown as number) + 1]],
  };

  const smallest = {
    up: [tones[smallestUp as number], tones[(smallestUp as number) + 1]],
    down: [tones[smallestDown as number], tones[(smallestDown as number) + 1]],
  };

  return { biggest, smallest };
}

function sum(s: number, d: number) {
  return s + d;
}

function getPercent(value: number, total: number) {
  if (total === 0) return "0%";
  return Math.round((value * 100) / total) + "%";
}

function getOctaveTimes(ns: Note[]) {
  const tones = ns.filter((n): n is Tone => !n.isRest && !n.isGrace);

  const oNotes = tones.reduce(
    (ns, tone) => {
      const key = Formatter.fromNote(tone);
      const o = tone.pitch.octave;
      if (!ns[o]) ns[o] = {};
      if (!ns[o][key]) ns[o][key] = 0;
      ns[o][key] = ns[o][key] + tone.duration;
      return ns;
    },
    {} as Record<number, Record<string, number>>
  );

  const totalDuration = Object.values(oNotes)
    .map((octave) => Object.values(octave).reduce(sum, 0))
    .reduce(sum, 0);

  const octaves = [] as OctaveFacts[];
  for (let o = 0; o < 8; o++) {
    if (!oNotes[o]) oNotes[o] = {} as Record<string, number>;

    const breakdown = getBreakdown(oNotes[o]);

    const octaveDuration = Object.values(oNotes[o]).reduce(sum, 0);

    const notes = Notes.asStrings(o as Octave).map((key) => {
      const keys = key.split("/");
      const noteDuration = keys.map((k) => oNotes[o][k] || 0).reduce(sum, 0);
      return {
        note: key,
        percent: getPercent(noteDuration, octaveDuration),
      };
    });

    const percent = getPercent(octaveDuration, totalDuration);

    octaves[o] = {
      breakdown,
      notes: notes as any,
      percent,
    };
  }

  return octaves;
}

function getBreakdown(notes: Record<string, number>) {
  const entries = Object.entries(notes);
  if (entries.length === 0) return ["0%", "0%", "0%"];

  let total = 0;
  let CtoE = 0;
  let FtoGSharp = 0;
  let AtoB = 0;
  for (const [note, duration] of entries) {
    total += duration;
    if (note.match(/[CDE][b#]?\d/)) CtoE += duration;
    if (note.match(/(F#?|G[b#]?|Ab)\d/)) FtoGSharp += duration;
    if (note.match(/(A#?|Bb?)\d/)) AtoB += duration;
  }

  return [
    getPercent(CtoE, total),
    getPercent(FtoGSharp, total),
    getPercent(AtoB, total),
  ];
}
