import React from "react";
import JSZip from "jszip";
import styled from "styled-components";

import Facts from "./Facts";

const Page = styled.div`
  height: 100vh;
  margin: 0 auto;
  max-width: 800px;
  padding: 2rem 4rem;
`;

const PageTitle = styled.h1`
  text-align: center;
`;

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  outline: 1px dotted black;
  width: 200px;
  margin: 0 auto;

  & p {
    margin: 0 auto;
  }

  & p:first-child {
    font-size: 1.5rem;
  }
`;

const parser = new DOMParser();

async function getScore(event: React.DragEvent<HTMLDivElement>) {
  const file = event.dataTransfer.items[0].getAsFile()!;
  const unzipped = await JSZip.loadAsync(file);

  if (!unzipped) return;

  const filename = Object.keys(unzipped.files).find(
    (name) => !name.startsWith("META")
  );

  if (!filename) return;

  const scoreXML = await unzipped.file(filename)!.async("string");
  const score = parser.parseFromString(scoreXML, "application/xml");

  return score;
}

const preventDefault = (e: React.DragEvent<HTMLDivElement>) =>
  e.preventDefault();

function App() {
  const [score, setScore] = React.useState<Document>();

  async function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    const score = await getScore(event);
    if (score) setScore(score);
  }

  return (
    <Page onDragOver={preventDefault} onDrop={preventDefault}>
      <PageTitle>Sheet Facts</PageTitle>
      <DropZone
        className="drop-zone"
        onDragOver={preventDefault}
        onDrop={handleDrop}
      >
        <p>Drag & Drop</p>
        <p>.mxl files</p>
      </DropZone>
      {score && <Facts score={score} />}
    </Page>
  );
}

export default App;
