import Notes from "models/Notes";
import { Grace, Tone } from "types/score";

const IntervalName = {
  23: "major fourteenth",
  22: "minor fourteenth",
  21: "major thirteenth",
  20: "minor thirtheenth",
  19: "perfect twelfth or tritave",
  18: "diminished twelfth or augmented eleventh",
  17: "perfect eleventh",
  16: "major tenth",
  15: "minor tenth",
  14: "major ninth",
  13: "minor ninth",
  12: "octave",
  11: "major seventh",
  10: "minor seventh",
  9: "major sixth",
  8: "minor sixth",
  7: "perfect fifth",
  6: "diminished fifth or augmented fourth",
  5: "perfect fourth",
  4: "major third",
  3: "minor third",
  2: "major second",
  1: "minor second",
  0: "perfect unison",
};

const IntervalModifier = {
  2: "double octave",
  3: "triple octave",
  4: "quadruple octave",
  5: "quintuple octave",
  6: "sextuple octave",
  7: "septuple octave",
  8: "octuple octave",
  9: "nonuple octave",
};

export function toIntervalDescription(
  lowest: Tone | Grace,
  highest: Tone | Grace
) {
  const semitones =
    Notes.toNumber(highest.pitch) - Notes.toNumber(lowest.pitch);

  let interval = "";
  if (semitones < 24)
    interval = IntervalName[semitones as keyof typeof IntervalName];
  else {
    const remainder = semitones % 12;
    const modifier = Math.floor(semitones / 12);

    interval = IntervalModifier[modifier as keyof typeof IntervalModifier];
    if (remainder) {
      interval =
        interval +
        " plus a " +
        IntervalName[remainder as keyof typeof IntervalName];
    }
  }

  const unit = semitones === 1 ? "semitone" : "semitones";

  return `${semitones} ${unit}/${interval}`;
}
