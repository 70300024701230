import { Grace, Pitch, Tone } from "types/score";

function standardize(pitch: Pitch) {
  const { step, alter } = pitch;

  if (step === "B" && alter === 1)
    return { ...pitch, step: "C", alter: undefined };
  if (step === "C" && alter === -1)
    return { ...pitch, step: "B", alter: undefined };
  if (step === "E" && alter === 1)
    return { ...pitch, step: "F", alter: undefined };
  if (step === "F" && alter === -1)
    return { ...pitch, step: "E", alter: undefined };

  return pitch;
}

const Alter = {
  "-1": "b",
  "1": "#",
};

function fromNote(tone?: Tone | Grace) {
  if (!tone) return "";

  const { step, octave, alter } = standardize(tone.pitch);
  const sign = alter ? Alter[alter] : "";
  return `${step}${sign}${octave}`;
}

const Formatter = { fromNote };

export default Formatter;
