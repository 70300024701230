import styled from "styled-components";

import Formatter from "Formatter";
import { SheetFacts } from "./facts";
import { toIntervalDescription } from "./Interval/description";
import { Octave } from "types/score";

const { fromNote } = Formatter;

const SpreadsheetSection = styled.div`
  margin: 1rem 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const CopyButton = styled.button`
  min-width: 100px;
`;

const TableScroller = styled.div`
  overflow-x: scroll;
  padding-bottom: 0.5rem;
  width: 100%;
`;

const STable = styled.table`
  border-collapse: collapse;
  font-size: 12px;
  table-layout: fixed;

  & th,
  & td {
    border: 1px solid #ccc;
    white-space: nowrap;
  }
`;

const headers = [
  "Song",
  "Artist",
  "Lowest Note",
  "Highest Note",
  "Overall Range",
  "Starting Note",
  "BID-bottom note",
  "BID-bottom lyric",
  "BID-top note",
  "BID-top lyric",
  "Biggest interval down",
  "BIU-bottom note",
  "BIU-bottom lyric",
  "BIU-top note",
  "BIU-top lyric",
  "Biggest interval up",
  "8v1 %",
  "8v2 %",
  "8v3 %",
  "8v4 %",
  "8v5 %",
  "8v6 %",
  "8v7 %",
];

export default function Spreadsheet({
  facts,
  selectedOctave,
}: {
  facts: SheetFacts;
  selectedOctave: Octave;
}) {
  const { artist, octaves, title, steps } = facts;
  const { starting, lowest, highest } = facts.notes;

  const outputData = [
    title,
    artist,
    fromNote(lowest),
    fromNote(highest),
    toIntervalDescription(lowest, highest),
    fromNote(starting),
    // biggest interval down
    fromNote(steps.biggest.down[1]),
    steps.biggest.down[1].lyric,
    fromNote(steps.biggest.down[0]),
    steps.biggest.down[0].lyric,
    toIntervalDescription(steps.biggest.down[1], steps.biggest.down[0]),
    // biggest interval up
    fromNote(steps.biggest.up[0]),
    steps.biggest.up[0].lyric,
    fromNote(steps.biggest.up[1]),
    steps.biggest.up[1].lyric,
    toIntervalDescription(steps.biggest.up[0], steps.biggest.up[1]),
    // octaves
    octaves[1].percent,
    octaves[2].percent,
    octaves[3].percent,
    octaves[4].percent,
    octaves[5].percent,
    octaves[6].percent,
    octaves[7].percent,
  ];

  const o = selectedOctave;
  const octave = octaves[o];

  const octaveHeaders = [
    ...[`C${o}-E${o}`, `F${o}-G#${o}`, `A${o}-B${o}`],
    ...octave.notes.map((o) => o.note),
  ];

  const octaveData = [
    octave.breakdown[0],
    octave.breakdown[1],
    octave.breakdown[2],
    ...octave.notes.map((n) => n.percent),
  ];

  return (
    <SpreadsheetSection>
      <h3>Spreadsheet format</h3>
      <TableScroller>
        <STable>
          <thead>
            <tr>
              {headers.map((heading, i) => (
                <th key={i}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {outputData.map((data, i) => (
                <td key={i}>{data}</td>
              ))}
            </tr>
          </tbody>
        </STable>
      </TableScroller>
      <ButtonGroup>
        <CopyButton
          onClick={() => navigator.clipboard.writeText(headers.join("\t"))}
        >
          Copy headers
        </CopyButton>
        <CopyButton
          onClick={() => navigator.clipboard.writeText(outputData.join("\t"))}
        >
          Copy data
        </CopyButton>
      </ButtonGroup>
      <h3>Octave {selectedOctave} breakdown</h3>
      <TableScroller>
        <STable>
          <thead>
            <tr>
              {octaveHeaders.map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {octaveData.map((s, i) => (
                <td key={i}>{s}</td>
              ))}
            </tr>
          </tbody>
        </STable>
      </TableScroller>
      <ButtonGroup>
        <CopyButton
          onClick={() =>
            navigator.clipboard.writeText(octaveHeaders.join("\t"))
          }
        >
          Copy headers
        </CopyButton>
        <CopyButton
          onClick={() => navigator.clipboard.writeText(octaveData.join("\t"))}
        >
          Copy data
        </CopyButton>
      </ButtonGroup>
    </SpreadsheetSection>
  );
}
