import Formatter from "Formatter";
import { Grace, Tone } from "types/score";
import { toIntervalDescription } from "./description";

interface IntervalProps {
  down?: boolean;
  lowest: Tone | Grace;
  highest: Tone | Grace;
}

export default function Interval({ down, lowest, highest }: IntervalProps) {
  const description = toIntervalDescription(lowest, highest);

  const [first, second] = down ? [highest, lowest] : [lowest, highest];

  return (
    <div>
      {(first.lyric || second.lyric) && (
        <div>
          {first.lyric}-{second.lyric}
        </div>
      )}
      <div>
        {Formatter.fromNote(first)}-{Formatter.fromNote(second)}
      </div>
      <div>{description}</div>
    </div>
  );
}
